<template>
    <v-app>
        <v-container fluid class="fill-height">
            <v-layout align-center justify-center>
                <material-card color="accent" full-header max-width="550">
                    <template #heading>
                        <div class="pa-6 white--text">
                            <div class="text-h3 text-center font-weight-light">
                                Вхiд
                            </div>
                        </div>
                    </template>

                    <validation-observer ref="obs" v-slot="{ invalid }">
                        <v-form class="pa-8">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-alert class="mal-6" type="error" v-if="errorMessage" outlined>
                                            {{errorMessage}}
                                        </v-alert>
                                    </v-col>

                                    <v-col cols="12">
                                        <validation-provider v-slot="{ valid }" rules="required">
                                            <v-text-field
                                                color="purple"
                                                label="Login"
                                                v-model="f.USER"
                                                :rules="[rules.required]"
                                                :success="valid"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider v-slot="{ valid, errors  }" rules="required">
                                            <v-text-field
                                                v-model="f.PASS"
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.required]"
                                                :type="show1 ? 'text' : 'password'"
                                                name="input-10-1"
                                                label="Password"
                                                :hint="errors[0]"
                                                color="purple"
                                                :success="valid"
                                                counter
                                                @click:append="show1 = !show1"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn color="primary" min-width="150" @click="submitForm" :disabled="invalid">
                                            Надiслати
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </validation-observer>
                </material-card>
            </v-layout>
        </v-container>
    </v-app>
</template>

<script>
// import axios from 'axios'
import {
    ValidationObserver,
    ValidationProvider
} from "vee-validate"
import {
    mapActions,
    mapMutations,
} from 'vuex'
  export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            show1: false,
            errorMessage: '',
            rules: {
                required: value => !!value || "Це поле обов'язкове.",
                min: (v) => {
                    return v ? v.length >= 8 || 'Min 8 characters' : false
                }
            },
            f: {
                USER: null,
                PASS: null
            }
        }
    },
    methods: {
    ...mapActions(['login']),
    ...mapMutations(['logOut']),

        getLocalStorage(f) {
            return JSON.parse(localStorage[f])
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        async submitForm() {
            // logOut - чистим localStorage на чслучай если выход произошёл не корректно
            this.logOut();

            this.errorMessage = ''
            this.loading = true
            // Execute reCAPTCHA with action "login".
            await this.$recaptchaLoaded()
            this.f.reCAPTCHA = await this.$recaptcha('login')

            if (this.f.reCAPTCHA) {
                this.login(this.f)
                    .then(d => {
                        this.loading = false
                        if (d.token && d.refreshToken) {
                            this.setLocalStorage('authToken', d.token)
                            this.setLocalStorage('refreshToken', d.refreshToken)
                            this.setLocalStorage('deviceId', d.deviceId)
                        }

                        this.$router.push(window.sessionStorage.getItem('referrerPath') || '/')
                    })
                    .catch(
                        (error) => {
                            this.loading = false
                            if (error.response && error.response.status == 403) {
                                this.errorMessage = 'Такого користувача не існує!'
                            } else if (error.response && error.response.status == 401) {
                                this.errorMessage = 'Помилка! Спробуйте ще раз!'
                            } else {
                                this.errorMessage = error.response.data.error.message || ''
                            }
                        }
                    )
            }
        },
    },
}
</script>
